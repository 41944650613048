import React, { Component } from 'react';
import { connect } from 'react-redux';

import { autologin } from 'actions/user';

import Main from './main';

class MainContainer extends Component {
  componentDidMount() {
    this.props.autologin();
  }

  render() {
    const user = this.props.user;
    return <Main user={user} />;
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  autologin: () => dispatch(autologin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
