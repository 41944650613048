import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from 'components/container';
import { admin } from 'utils/role';
import { gravatar } from 'utils/avatar';

import './header.css';

const Header = ({ user }) => (
  <header styleName="header">
    <Container>
      <div styleName="content">
        <NavLink to="/" styleName="home">
          <h1>Anticàmera</h1>
        </NavLink>
        <nav styleName="main-nav">
          <NavLink to="/locations">Animalario</NavLink>
          <NavLink exact to="/productions">
            Produzioni
          </NavLink>
          <NavLink exact to="/clients">
            Clienti
          </NavLink>
          {admin(user) && (
            <NavLink exact to="/users">
              Utenti
            </NavLink>
          )}
          {admin(user) && (
            <NavLink exact to="/related">
              Opzioni
            </NavLink>
          )}
        </nav>
        <nav>
          {user ? (
            <NavLink to="/user">
              <img
                styleName="thumb"
                src={gravatar(user.hash)}
                alt={user.firstname}
              />
            </NavLink>
          ) : (
            <NavLink to="/login">Login</NavLink>
          )}
        </nav>
      </div>
    </Container>
  </header>
);

export default Header;
