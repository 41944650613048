exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js?modules&importLoaders=true&localIdentName=[path]___[name]__[local]___[hash:base64:5]!../../styles/variables.css"), "");

// Module
exports.push([module.id, ".src-web-containers-main-___main__container___2yQpJ {\n  padding-bottom: var(--size--10);\n}\n", ""]);

// Exports
exports.locals = {
	"container": "src-web-containers-main-___main__container___2yQpJ"
};