import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './header';

import PrivateRoute from './private-route';
import loading from './loading';

const Public = lazy(() => import('./routes-public'));
const Management = lazy(() => import('./routes-management'));
const Login = lazy(() => import('containers/login'));
    
export default ({ user }) => (
  <div>
    <Suspense fallback={loading}>
      <Switch>
        <Route path="/public" component={Public} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute user={user} component={Management} />
      </Switch>
    </Suspense>
  </div>
);
