import { SET_FILTER, RESET_FILTER } from 'actions/search';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
      return Object.assign({}, state, { [action.form]: action.filter });

    case RESET_FILTER:
      return Object.assign({}, state, { [action.form]: {} });

    default:
      return state;
  }
};
