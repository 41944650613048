import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';

export default createMuiTheme({
  palette: {
    background: {
      default: 'hsl(210, 36%, 96%)',
    },
    primary: blue,
    secondary: amber,
    grey: {
      300: 'hsl(210, 36%, 96%)',
      A100: 'hsl(210, 31%, 80%)',
    },
  },
  typography: {
    useNextVariants: true,
  },
});
