import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import itLocale from 'date-fns/locale/it';

import store from 'store';
import Main from 'containers/main';
import theme from 'styles/theme';

import { client } from '../apollo';
import '../styles/common.css';

const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={3} anchorOrigin={anchorOrigin}>
            <React.Fragment>
              <CssBaseline />
              <Main />
            </React.Fragment>
          </SnackbarProvider>
        </Provider>
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default hot(App);
