import {
  login as apiLogin,
  autologin as apiAutologin,
  logout as apiLogout,
} from 'utils/api/auth';

import { client } from 'apollo';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

const userLogin = () => ({
  type: USER_LOGIN,
});

const userLoginSuccess = user => ({
  type: USER_LOGIN_SUCCESS,
  user,
});

const userLoginFailure = error => ({
  type: USER_LOGIN_FAILURE,
  error,
});

const userLogout = () => ({
  type: USER_LOGOUT,
});

const userLogoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
});

const userLogoutFailure = error => ({
  type: USER_LOGOUT_FAILURE,
  error,
});

export const login = data => dispatch => {
  dispatch(userLogin());

  return apiLogin(data)
    .then(user => {
      dispatch(userLoginSuccess(user));
    })
    .catch(error => {
      console.log(error);
      alert('Password e/o email errate', error.message);
      dispatch(userLoginFailure(error));
    });
};

export const autologin = () => dispatch => {
  dispatch(userLogin());

  return apiAutologin()
    .then(user => {
      dispatch(userLoginSuccess(user));
    })
    .catch(error => {
      dispatch(userLoginFailure(error));
    });
};

export const logout = () => dispatch => {
  dispatch(userLogout());

  return apiLogout()
    .then(() => {
      dispatch(userLogoutSuccess());
      client.resetStore();
    })
    .catch(error => {
      dispatch(userLogoutFailure(error));
    });
};
