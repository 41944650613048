import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_SUCCESS,
} from 'actions/user';

export default (state = null, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case USER_LOGIN_FAILURE:
    case USER_LOGOUT_SUCCESS:
      return null;

    case USER_LOGIN_SUCCESS:
      return action.user;

    default:
      return state;
  }
};
