import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import './main.css';

const Main = ({ user }) => (
  <BrowserRouter>
    <Routes user={user} />
  </BrowserRouter>
);

export default Main;
