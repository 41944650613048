export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';

export const setFilter = (form, filter) => ({
  type: SET_FILTER,
  form,
  filter,
});
export const resetFilter = form => ({
  type: RESET_FILTER,
  form,
});
