exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js?modules&importLoaders=true&localIdentName=[path]___[name]__[local]___[hash:base64:5]!../../styles/variables.css"), "");

// Module
exports.push([module.id, ".src-web-components-container-___container__contain___xK-s3 {\n  padding: 0 var(--size--5);\n  max-width: 1600px;\n  margin: 0 auto;\n}", ""]);

// Exports
exports.locals = {
	"contain": "src-web-components-container-___container__contain___xK-s3"
};