import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from '../reducers';

const middlewares = [];
middlewares.push(thunkMiddleware);

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

export default createStore(reducers, applyMiddleware(...middlewares));
