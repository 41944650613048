exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js?modules&importLoaders=true&localIdentName=[path]___[name]__[local]___[hash:base64:5]!../../styles/variables.css"), "");

// Module
exports.push([module.id, ".src-web-containers-main-___header__header___a1tb2 {\n  background: linear-gradient(\n    to right,\n    var(--color--neutral-4) 0%,\n    var(--color--neutral-3) 47%,\n    var(--color--neutral-2) 100%\n  );\n}\n.src-web-containers-main-___header__header-public___2gb-b {\n  background: #fff;\n}\n.src-web-containers-main-___header__header___a1tb2 h1 {\n  color: var(--color--neutral-9);\n  font-size: var(--fontsize--7);\n  padding: 0;\n  margin: var(--size--3) 0;\n  font-weight: 300;\n}\n.src-web-containers-main-___header__header-public___2gb-b h1 {\n  color: #000;\n  font-size: var(--fontsize--3);\n}\n.src-web-containers-main-___header__home___fowU8:hover {\n  text-decoration: none;\n}\n.src-web-containers-main-___header__content___3hA9U {\n  display: flex;\n}\n.src-web-containers-main-___header__header___a1tb2 nav {\n  display: flex;\n}\n.src-web-containers-main-___header__header___a1tb2 nav a {\n  display: flex;\n  align-items: center;\n  font-size: var(--fontsize--2);\n  color: var(--color--neutral-9);\n  margin: 0 var(--fontsize--3);\n  font-weight: 500;\n}\n.src-web-containers-main-___header__header___a1tb2 nav a:hover {\n  text-decoration: none;\n}\n.src-web-containers-main-___header__header___a1tb2 nav a.active {\n  color: var(--color--neutral-accent);\n}\n.src-web-containers-main-___header__main-nav___19Cn4 {\n  flex-grow: 1;\n  justify-content: center;\n}\n.src-web-containers-main-___header__thumb___nzl4g {\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n}", ""]);

// Exports
exports.locals = {
	"header": "src-web-containers-main-___header__header___a1tb2",
	"header-public": "src-web-containers-main-___header__header-public___2gb-b src-web-containers-main-___header__header___a1tb2",
	"home": "src-web-containers-main-___header__home___fowU8",
	"content": "src-web-containers-main-___header__content___3hA9U",
	"main-nav": "src-web-containers-main-___header__main-nav___19Cn4",
	"thumb": "src-web-containers-main-___header__thumb___nzl4g"
};